.subjects-page {
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.subjects-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}



.suContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.subjects-container {
  display: block;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 90%;
  max-width: 1000px;
  background-color: #743475;
  border-radius: 20px;
  padding: 20px;
  margin-top: 3%;
}

.suads-container {
  margin: 20px auto;
  width: 90%;
}

.category-name {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
}

.subject-item {
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.subject-item:hover {
  background-color: #c480ba;
}

.loading,
.error,
.no-data {
  text-align: center;
  margin-top: 50px;
  color: white;
}
.subject-theory {
  background-color: #e1a158;
  height: 30px;
  width: 150px;
  font-size: 1.0vw;
  align-content: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -10px;
}

.subject-theory:hover {
  background-color: #fff9ff;
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);

}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .subjects-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .category-name {
      font-size: 16px;
  }

  .subject-item {
      font-size: 14px;
      padding: 10px;
  }

  .subject-theory {
    font-size: 2.5vw;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .subjects-container {
      grid-template-columns: 1fr;
      gap: 15px;
  }

  .category-name {
      font-size: 14px;
  }

  .subject-item {
      font-size: 12px;
      padding: 8px;
  }
}
