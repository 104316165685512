.country-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .file-input {
    margin-bottom: 15px;
  }
  
  .image-preview {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    border-radius: 8px;
  }
  
  .btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-create {
    background-color: #4caf50;
    color: white;
  }
  
  .btn-create:hover {
    background-color: #45a049;
  }
  
  .btn-update {
    background-color: #2196f3;
    color: white;
  }
  
  .btn-update:hover {
    background-color: #1e88e5;
  }
  
  .btn-edit {
    background-color: #ffc107;
    color: white;
    margin-top: 10px;
  }
  
  .btn-edit:hover {
    background-color: #ffb300;
  }
  
  .btn-back {
    background-color: #f44336;
    color: white;
    margin-top: 20px;
  }
  
  .btn-back:hover {
    background-color: #e53935;
  }
  
  .countries-list {
    margin-top: 20px;
  }
  
  .country-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .country-item span {
    font-size: 16px;
  }
  
  .country-item .btn {
    width: auto;
    padding: 5px 10px;
    font-size: 14px;
  }
  