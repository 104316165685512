.promo-details-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  
  .header-title {
    color: #333;
    text-align: center;
  }
  
  .details-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .details-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    width: 80%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .detail-text {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .details-item:hover {
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
  }
  