.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .textInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button.delete {
    background-color: #dc3545;
  }
  
  .button.delete:hover {
    background-color: #a71d2a;
  }
  
  .subjectList {
    margin-top: 20px;
  }
  
  .subjectItem {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .subjectName {
    flex-grow: 1;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
  }
  
  .subjectName:hover {
    text-decoration: underline;
  }
  