.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .text-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .test-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .test-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .test-info {
    cursor: pointer;
    flex: 1;
  }
  
  .test-info p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    background-color: #ccc;
  }
  
  .edit-button {
    background-color: #2196f3;
    margin: 10px;
  }
  
  .edit-button:hover {
    background-color: #1e88e5;
  }
  
  .delete-button {
    background-color: #f44336;
  }
  
  .delete-button:hover {
    background-color: #e53935;
  }
  