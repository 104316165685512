.ads2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    
    overflow: hidden;
  }
  
  .ads-image-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .ads-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .ads2-container {
      height: 100px;
     
    }
  }
  