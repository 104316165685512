.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button {
    display: inline-block;
    padding: 10px 15px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-button {
    background-color: #4caf50;
  }
  
  .edit-button {
    background-color: #2196f3;
  }
  
  .delete-button {
    background-color: #f44336;
  }
  
  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .list {
    margin-top: 20px;
  }
  
  .item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .item-content {
    flex-grow: 1;
    cursor: pointer;
  }
  
  .item-text {
    margin: 0;
    font-size: 16px;
  }
  
  .item-content:hover {
    background-color: #f1f1f1;
  }
  