.admin-edit-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .dropdown,
  .text-input,
  .checkbox-label,
  .editbutton {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .text-input {
    height: 100px;
    resize: none;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkbox {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  
  .checkbox-label {
    margin-left: 5px;
    color: #333;
    font-size: 16px;
  }
  
  .image-preview {
    display: block;
    width: 100%;
    height: auto;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .editbutton {
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .editbutton:hover {
    background-color: #45a049;
  }
  