.footer {
    padding: 15px 5%;
    background-color: #303730;
    margin-top: 5%;
    color: white;
    text-align: center;
    font-size: 1rem; /* Base font size */
    box-sizing: border-box; /* Ensure padding is included in width calculations */
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .footer {
      font-size: 0.9rem; /* Slightly reduce font size for small screens */
      padding: 10px 5%; /* Adjust padding for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      font-size: 0.8rem; /* Further reduce font size for very small screens */
      padding: 8px 5%; /* Minimal padding for tiny screens */
    }
  }
  