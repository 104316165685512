/* Container for the entire page */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Logo styles */
  .uplogo {
    width: 300px;
    height: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Label styles */
  .label {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  /* Dropdown (select) styles */
  .dropdown {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #f9f9f9;
  }
  
  /* Affiliate selection container */
  .affiliate-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Card styles for Institute and Student buttons */
  .card {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    flex: 1;
    transition: all 0.3s ease;
  }
  
  /* Highlight selected card */
  .card-selected {
    border: 2px solid #4caf50;
    background-color: #e8f5e9;
  }
  
  /* Text input field */
  .upinput {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Button styles */
  .button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  