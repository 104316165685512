.news-page {
    padding: 20px;
    background-color: #e0d6d6;
  }
  
  .news-search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .category-list {
    display: flex;
    flex-wrap: wrap; /* Ensure categories wrap on smaller screens */
    gap: 10px; /* Adds consistent spacing between items */
    margin-bottom: 20px;
    
  }
  
  .main-category-item {
    padding: 10px;
    background-color: #eee;
    border-radius: 4px;
    cursor: pointer;
  
  }
  
  .main-category-item.selected {
    background-color: #007bff;
    color: white;
  }
  
  .news-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .news-card {
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 50%
  }
  
  .news-img-container {
    width: 100%;
    height: 400px; /* Reduced default height */
    overflow: hidden;
  }
  
  .news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .news-text-container {
    padding: 10px;
  }
  
  .news-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .news-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .news-see-more {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .news-img-container {
      height: 150px; /* Adjust image height for smaller screens */
    }

    .news-card {
        width: 100%;
    }
    
    .news-title {
      font-size: 16px;
    }
    
    .news-description {
      font-size: 12px;
    }
    
    .category-list {
      justify-content: space-between; /* Adjust category alignment */
    }
  }
  
  @media (max-width: 480px) {
    .news-page {
      padding: 10px;
    }
  
    .search-input {
      font-size: 14px;
      padding: 8px;
    }
  
    .news-title {
      font-size: 14px;
    }
  
    .news-description {
      font-size: 12px;
    }
  }
  