.admin-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .category-input {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .text-input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .category-list {
    margin-top: 20px;
    
  }
  
  .category-item {
    min-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .category-name {
    font-size: 18px;
    color: #000;
  }
  
  .category-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .edit-button {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #218838;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  