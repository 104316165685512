/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #b393a5;
    color: #333;
  }
  
  /* Container Styles */
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0px;
  }

  .error-text {
    color: red;
    font-size: 0.85rem;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  
  
  /* Image Container */
  .image-container {
    position: relative;
    width: 100%;
    margin-top: -150px;
    max-height: 500px;
    overflow: hidden;
    display: flex;
  }
  
  .auth-image {
    width: 100%;
    margin-top: -100px;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(143, 102, 142, 0.5);
  }
  
  /* Form Container */
  .form-container {
    background: #fff;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .password-hint {
    font-size: 0.85rem;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .password-hint.red {
    color: red;
  }
  .password-hint.green {
    color: green;
  }
  .password-hint.black {
    color: black;
  }
  
  
  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  .login-input[type = "password"] {
    width: 100%;
    padding: 10px;
   
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 33px;
    font-size: 16px;
  }
  
  .password-container {
    position: relative;
    width: 100%;
  }
  
  .toggle-password {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #601484;
    font-size: 14px;
    cursor: pointer;
  }
  
  .toggle-password:hover {
    text-decoration: underline;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    background: #631186;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .forgot-password {
    background: none;
    border: none;
    color: #8a2fa8;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .register-button {
    width: 100%;
    padding: 12px;
    background: #fff;
    color: #8a33b9;
    border: 2px solid #782683;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .register-button:hover {
    background: #953cab;
    color: #fff;
  }
  
  /* Error Message */
  .error-message {
    color: #d9534f;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }

  .back-button {
    background: transparent;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .back-button:hover {
    text-decoration: underline; /* Add hover effect if needed */
  }

  .white-arrow {
    color: white;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-container {
      padding: 15px;
    }
  
    .auth-image {
      height: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .logo {
      width: 100px;
      height: 100px;
    }
  
    .input,
    .login-button,
    .register-button {
      font-size: 14px;
    }
  
    .toggle-password {
      font-size: 12px;
    }
  }
  