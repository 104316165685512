.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .year-text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  
  
  .button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .year-list {
    list-style-type: none;
    padding: 0;
  }
  
  .year-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .year-item button {
    margin-left: 10px;
  }
  
  .navigate-button {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
 
  
  