.ads-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    margin-left: 30px;
    overflow: hidden;
  }
  
  .ads-image-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .ads-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .ads-container {
      height: 100px;
      margin-left: 6.5px;
    }
  }
  