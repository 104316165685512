.edit-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    min-height: 600px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #555;
  }
  
  .form-input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  textarea.form-input {
    resize: vertical;
    height: 100px;
  }
  
  .image-preview-container {
    text-align: center;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 300px;
    margin-top: 15px;
    border-radius: 8px;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  