/* Modal overlay to center content */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1000;
    
  }
  
  /* Modal container for content */
  .modal-container {
    width: 90%;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    min-height: 100px;
    position: fixed;
    justify-self: center;
  }
  
  /* Title text */
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Message text */
  .modal-message {
    font-size: 2rem;
    margin: 10px 0;
    color: #555;
  }
  
 
  
  /* Button styles */
  .button {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    color: white;
  }
  
  .button-next {
    background-color: #4caf50;
    margin-top: 15px;
  }
  
  @media (max-width: 600px) {
    .modal-container {
      padding: 15px;
    }
  
    .modal-title {
      font-size: 1.2rem;
    }
  
    .modal-message {
      font-size: 0.9rem;
    }
  
    .button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  }
  