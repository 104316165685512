body {
    font-family: 'Arial', sans-serif;
    background-color: #b596ba;
    margin: 0;
    padding: 0;
  }
  
  .main-exam-screen {
    max-width: 50%; /* Adjusts to smaller screen sizes */
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    background-image: url("/src/assets/images/LiteUpb.jpg");
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Prevents width issues with padding */
  }
  
  .timer {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px auto;
    padding: 10px;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    max-width: 300px; /* Prevents it from being too wide */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
  }
  
  .questions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .question-card {
    background: #f9f9fc;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: left;
    padding: 15px;

    transition: transform 0.3s, box-shadow 0.3s;
    min-width: 300px; /* Minimum width for the card */
    word-wrap: break-word; /* Ensure long words wrap within the card */
  }
  
  .question-card:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .question-card p {
    margin: 20px;
  }
  
  .option {
    display: block;
    width: 50%;
    margin: 20px 0;
    padding: 10px 15px;
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;
    min-width: 200px; /* Minimum width for options */
    word-wrap: break-word; /* Ensure long words wrap within the button */
  }
  
  .option:hover {
    background: #f0f0f5;
  }
  
  .option.selected {
    background: #e0ffe6;
    border-color: #28a745;
    color: #155724;
    font-weight: bold;
  }
  
  .submit-button {
    display: inline-block;
    width: 100%;
    padding: 15px;
    background: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background: #0056b3;
  }
  
  @media (max-width: 768px) {
    .main-exam-screen {
      padding: 15px;
      min-width: 100%;
    }
  
    .timer {
      font-size: 1.2rem;
      position: fixed;
    }
  
    .option {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  
    .submit-button {
      font-size: 1rem;
      padding: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .timer {
      font-size: 1rem;
      padding: 8px;
      position: fixed;
    }
  
    .option {
      font-size: 0.8rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }
  