.admin-edit-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .admin-edit-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
    transition: background-color 0.3s;
  }
  
  .checkbox.selected {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  label {
    font-size: 16px;
    color: #333;
  }
  
  .update-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .update-button:hover {
    background-color: #45a049;
  }
  