.admin-edit-exam {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    min-height: 600px;
    font-family: Arial, sans-serif;
  }
  
  .edit-form .form-group {
    margin-bottom: 15px;
  }
  
  .edit-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .edit-form input,
  .edit-form .toggle-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .toggle-button {
    cursor: pointer;
    background-color: #ccc;
    color: #000;
    text-align: center;
    border: none;
    border-radius: 5px;
  }
  
  .toggle-button.active {
    background-color: green;
    color: white;
  }
  
  .image-preview img {
    max-width: 100%;
    
    display: block;
    margin-bottom: 10px;
  }
  
  .Exsubmit-button {
    background-color: blue;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .Exsubmit-button:hover {
    background-color: darkblue;
  }
  