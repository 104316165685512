.admin-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .admin-text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .student-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .student-item {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s;
  }
  
  .student-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .student-info {
    font-size: 16px;
    font-weight: 500;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  