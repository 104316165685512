.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .picker,
  .textInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .textInput {
    resize: none;
    height: 80px;
  }
  
  .fileInput {
    margin-bottom: 15px;
  }
  
  .toggleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
  }
  
  .checkboxSelected {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .checkboxLabel {
    font-size: 16px;
    cursor: pointer;
  }
  
  .button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .existexam {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
  }
  
  .existexamtext {
    font-size: 16px;
  }
  
  .exambutton {
    margin: 5px 0;
    margin-left: 50px;
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .exambutton:hover {
    background-color: #a71d2a;
  }
  
  .image-preview-container {
    text-align: center;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 300px;
    margin-top: 15px;
    border-radius: 8px;
  }