.promo-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .promo-list {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
    align-items: center;
  }
  
  .promo-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .promo-item:hover {
    background-color: #007bff;
    color: white;
  }
  
  .date-text {
    font-size: 16px;
    font-weight: bold;
  }
  