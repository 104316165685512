.institution-container {
    padding: 20px;
    min-height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    
  }
  
  .institution-header {
    
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .institutionexam-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 100px;
    justify-items: center;
    padding-left: 100px;
    padding-right: 100px;
    height: auto;
  }
  
  .institutionexam-card {
    display: grid;
    background: rgb(169, 51, 137);
    width: 200px;
    height: 200px;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .institutionexam-card:hover {
    transform: scale(1.05);
  }
  
  .institutionexam-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .institutionexam-name {
    margin-top: 10px;
    font-weight: bold;
    color: #f8f5f5;
    font-size: 1rem;
  }
  
  .loading-container,
  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .institutionexam-card {
      flex: 1 0 calc(33.33% - 20px); /* Three cards per row */
      max-width: calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .institutionexam-card {
      flex: 1 0 calc(50% - 20px); /* Two cards per row */
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .institutionexam-card {
      flex: 1 0 calc(100% - 20px); /* One card per row */
      max-width: calc(100% - 20px);
    }
  
    .institution-header {
      flex-direction: column;
      text-align: center;
    }
  }
  