.admin-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .add-button, .test-button, .edit-button, .delete-button {
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-button {
    background-color: #28a745;
    color: white;
  }
  
  .test-button {
    background-color: #007bff;
    color: white;
  }
  
  .edit-button {
    background-color: #ffc107;
    color: black;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .test-list {
    margin-top: 20px;
  }
  
  .test-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  