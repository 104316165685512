

.questions-screen {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.header {
  text-align: center;
  margin-bottom: 50px;
}

.header h1 {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin: auto;
  
}

.question-card {
  width: 100%;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.question-text {
  width: 100px;
}

.question-card img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  width: 100%;

}

 .option {
  flex: 1 1 calc(50% - 5px);
  max-width: calc(40% - 5px);
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 10px;
 
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.options .option:hover {
  border-color: #007bff;
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
}

.options .option.selected {
  border-color: #28a745;
  background: #e6ffe6;
}

.options .option img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  max-width: 600px;
}

.navigation button {
  padding: 10px 20px;
  font-size: 1rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.navigation button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.navigation button:hover:not(:disabled) {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header h1 {
    font-size: 1.2rem;
  }
  .question-text {
    width: 50px;
  }

  .options img{
    padding: 8px 12px;
    font-size: 0.9rem;
    width: 100%;
    
  }

  .navigation {
    flex-direction: column;
    gap: 30px;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .question-card {
    padding: 15px;
  }

  .navigation button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
