/* SecUpdateProfilePage.css */
.uPcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
  }
  
  .uPlabel {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .uPpicker {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }
  
  .picker {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .proceed-button {
    width: 100%;
    max-width: 400px;
    padding: 12px;
    font-size: 1rem;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .proceed-button:hover {
    background-color: #45a049;
  }
  
  .uPcontainer p {
    font-size: 1rem;
    color: #888;
  }
  
  .proceed-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  