/* General styles for the modal overlay */
.Goldmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  /* Background of the modal with a responsive design */
  .Goldmodal-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  /* Alert container with adaptive sizing */
  .Goldalert-container {
    background: rgba(255, 255, 255, 0.95);
    padding: 20px;
    border-radius: 10px;
   
    
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 90%; /* Adapt to smaller screens */
  }
  
  /* Inner card styles */
  .Goldalert-card {
    margin-bottom: 20px;
    justify-items: center;
  }
  
  /* Title text */
  .Goldalert-title {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
    word-wrap: break-word;
  }
  
  
  .gold-display {
    display: flex;
    align-items: center;
  }
  
  .gold-icon {

    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  
  .gold-text {
    font-size: 1.2em;
    font-weight: bold;
    color: black;
    margin-left: -10px;
  }
  
  /* Message below the button */
  .Goldalert-message {
    font-size: 1em;
    color: #666;
  }
  
  /* Action buttons styling */
  .Goldalert-actions {
    display: flex;
    flex-wrap: wrap; /* Wrap buttons on smaller screens */
    justify-content: space-around;
    gap: 10px;
  }
  
  .buy-now-button,
  .cancel-button {
    flex: 1; /* Make buttons take equal width */
    min-width: 100px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .buy-now-button {
    background-color: #4caf50;
    color: white;
    
    
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .Goldalert-container {
      padding: 15px;
      
    }
    
    .Goldalert-actions {
      display: block;
      flex-wrap: wrap; /* Wrap buttons on smaller screens */
      justify-content: space-around;
      gap: 10px;
    }
    
  
    .Goldalert-title {
      font-size: 1.2em;
    }
  
    .gold-icon {
      width: 80px;
      height: 80px;
    }
  
    .gold-text {
      font-size: 1em;
    }
  
    .buy-now-button,
    .cancel-button {
      font-size: 0.9em;
      padding: 8px 15px;
    }
  }
  