.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fadce8;
}

.slideshow {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  animation: slideshow 16s infinite;
  background-size: cover;
  background-position: center;
}

/* Keyframes for the slideshow */
@keyframes slideshow {
  0% {
    background-image: url('../assets/home1.jpg'); /* Replace with actual path */
  }
  25% {
    background-image: url('../assets/home2.jpg');
  }
  50% {
    background-image: url('../assets/home3.jpg');
  }
  75% {
    background-image: url('../assets/logo3.png');
  }
  100% {
    background-image: url('../assets/home4.jpg');
  }
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #45a049;
}

.play-store {
  text-align: center;
  margin: 30px auto;
  max-width: 800px;
}

.play-store h2 {
  font-size: 24px;
  color: #da1ae8;
  margin-bottom: 10px;
}

.play-store p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.play-store-image {
  max-width: 200px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.play-store-image:hover {
  transform: scale(1.1);
}

content-section {
  text-align: left;
  margin: 30px auto;
  max-width: 800px;
}

.content-section section {
  margin-bottom: 30px;
}

.content-section h2 {
  font-size: 24px;
  color: #e91aa4;
  margin-bottom: 10px;
}

.content-section p,
.content-section ul {
  font-size: 16px;
  line-height: 1.5;
}

.content-section ul {
  list-style: disc;
  margin-left: 20px;
}

.content-section a {
  color: #ee26dd;
  text-decoration: none;
}

.content-section a:hover {
  text-decoration: underline;
}

.download-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  cursor: pointer;
  margin: 20px 0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
