/* Container for the entire dashboard */
.dashboard-container {
    padding: 20px;
    margin: 0 auto;
    min-height: 600px;
    font-family: Arial, sans-serif;
    max-width: 50%; /* Set a maximum width */
    width: 100%; /* Ensure it adjusts to smaller screens */
    box-sizing: border-box;
  }
  
  /* Header styling */
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  /* User counts section */
  .user-counts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Buttons container */
  .button-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 15px;
  }
  
  /* Button styles */
  .Dbutton {
    padding: 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .Dbutton:hover {
    background-color: #0056b3;
  }
  
  /* Loading spinner container */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Spinner animation */
  .spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  /* Keyframes for spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Media queries for smaller screens */
  @media (max-width: 768px) {
    .dashboard-header {
      font-size: 1.5em;
    }
  
    .Dbutton {
      font-size: 0.9em;
      padding: 12px;
    }
  }
  