.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .category-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .category-name {
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }
  
  .btn {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: white;
  }
  
  .create-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .create-btn {
    background-color: #2196f3;
    color: white;
  }
  