/* Container */
.edit-question-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  .edit-question-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Text inputs and textarea */
  .text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: vertical;
    box-sizing: border-box;
  }
  
  /* Image preview */
  .image-preview {
    display: block;
    max-width: 100%;
    max-height: 300px;
    margin: 15px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Button */
  .edit-button {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #45a049;
  }
  