.admin-edit-container {
    max-width: 90%; /* Adjust to screen width */
    width: 600px; /* Default width for larger screens */
    margin: 20px auto; /* Center the container */
    padding: 20px;
    min-height: 600px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for better aesthetics */
  }
  
  h1 {
    margin-bottom: 20px;
    font-size: 1.5rem; /* Use rem for scalable font sizes */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 10px; /* Slightly increased padding for better usability */
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
  }
  
  .submit-button {
    padding: 12px 18px;
    font-size: 1rem;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .submit-button:hover {
    background: #218838;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .admin-edit-container {
      width: 100%; /* Full width on smaller screens */
      padding: 15px;
      min-height: 500px; /* Adjust height */
    }
  
    h1 {
      font-size: 1.25rem; /* Slightly smaller heading on smaller screens */
    }
  
    .form-input {
      padding: 8px; /* Adjust padding for smaller screens */
    }
  
    .submit-button {
      padding: 10px 14px; /* Adjust button size for smaller screens */
    }
  }
  