/* AdminNewsListScreen.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .news-list {
    margin-bottom: 30px;
  }
  
  .news-item {
    display: block;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .news-item strong {
    color: #555;
  }
  
  .btn-edit,
  .btn-delete {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .btn-edit {
    background-color: #4caf50;
    color: #fff;
    margin-right: 10px;
    margin-top: 20px;
  }
  
  .btn-delete {
    background-color: #f44336;
    color: #fff;
    margin-top: 20px;
  }
  
  .btn-edit:hover {
    background-color: #45a049;
  }
  
  .btn-delete:hover {
    background-color: #e53935;
  }
  
  .form {
    background-color: #f4f4f4;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .form input[type="text"],
  .form textarea,
  .form select,
  .form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .form textarea {
    resize: none;
    height: 100px;
  }
  
  .image-preview {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
  }
  
  .btn-submit {
    padding: 10px 20px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .btn-submit:hover {
    background-color: #1976d2;
  }
  
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #333;
  }
  