.admin-exam-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .admin-exam-text-input {
    width: 100%;
    padding: 10px;
    
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .admin-exam-toggle-container {
    margin: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .admin-exam-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .admin-exam-checkbox-selected {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .admin-exam-checkbox-label {
    font-size: 16px;
    color: #333;
  }
  
  .admin-exam-image-preview {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .admin-exam-button {
    background-color: #27d06d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    
    text-align: center;
  }
  
  .admin-exam-button:hover {
    background-color: #0056b3;
  }
  
  .admin-exam-button-text {
    color: white;
    font-weight: bold;
  }
  
  .admin-exam-exam-list {
    margin-top: 20px;
  }
  
  .admin-exam-exam-item {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f9fa;
  }
  
  .admin-exam-exam-details {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .admin-exam-exam-buttons {
    display: flex;
    gap: 10px;
  }
  
  .admin-exam-exam-button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    margin: 20px;
    cursor: pointer;
    min-height: 50px;
    font-size: 14px;
  }
  
  .admin-exam-exam-button:hover {
    background-color: #0056b3;
  }
  
  .admin-exam-delete-button {
    background-color: #dc3545;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    margin: 20px;
    cursor: pointer;
    height: 50px;
    font-size: 14px;
  }
  
  .admin-exam-delete-button:hover {
    background-color: #c82333;
  }
  
  .admin-exam-activity-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  