.verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
  }
  
  .verification-text {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #333;
  }
  
  .verification-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .verification-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .loading-indicator {
    margin-top: 20px;
    font-size: 1rem;
    color: #007bff;
  }
  