/* Modal styling */
.F-alert-modal {
  position: fixed;
  top: 45%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for exact centering */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
  display: flex;
  justify-content: center;
  align-items: center; /* Ensures vertical centering */
  z-index: 1000;
  overflow: hidden; /* Prevents scrolling inside modal */
  touch-action: none; /* Prevent touch-based movement */
}

/* Background image */
.F-alert-background {
  background-size: cover;
  background-position: center;
  padding: 5vw; /* Responsive padding */
  border-radius: 15px;

  width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
}

/* Container of alert content */
.F-alert-container {
  background-color: white;
  border-radius: 15px;
  padding: 3vw;
  text-align: center;
  max-width: 700px; /* Maximum width for large screens */
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh; /* Ensures the modal is not too small */
  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
}

/* Header styling */
.F-alert-header {
  margin-bottom: 2vw;
}

.F-alert-title {
  font-size: 4vw; /* Font size adapts to screen size */
  font-weight: bold;
  color: #333;
}

.F-alert-message {
  font-size: 2.5vw;
  color: black;
  background-color: #d9534f;
  padding: 1vw;
  border-radius: 10px;
}

/* Scrollable failed questions */
.failed-questions-container {
  max-height: 50vh; /* Allows for scrolling while maintaining view */
  overflow-y: auto;
  margin-bottom: 2vw;
}

.failed-questions-scroll {
  padding: 0 2vw;
}

/* Styling for each failed question */
.failed-question {
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2vw;
  align-items: end;
  padding: 1vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for failed question info */
.failed-question-title {
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.correct-answer {
  color: white;
  background-color: #389438;
}

.failed-answer {
  color: white;
  margin-bottom: 10px;
  background-color: #e31e1e;
}

.solution {
  color: black;
  font-style: italic;
  background-color: rgb(237, 144, 22);
  margin-top: 10px;
}

/* Retry button container */
.retry-button-container {
  margin-top: 4vw;
}

.retry-button {
  background-color: #5cb85c;
  padding: 1vw 4vw;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5vw;
}

.retry-text {
  font-size: 2.5vw;
  color: white;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .F-alert-container {
    width: 100%;
    
    position: fixed;
  }
  .F-alert-title {
      font-size: 6vw;
  }

  .F-alert-message {
      font-size: 4vw;
  }


  .failed-question {
      padding: 5vw;
  }

  .retry-button {
      padding: 3vw 5vw;
      font-size: 4vw;
  }
}

@media (max-width: 480px) {
  .F-alert-title {
      font-size: 8vw;
  }

  .F-alert-message {
      font-size: 5vw;
  }


  .failed-question {
      padding: 6vw;
  }

  .retry-button {
      padding: 4vw 6vw;
      font-size: 4vw;
  }
}
