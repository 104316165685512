.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff1493;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
  flex-wrap: wrap; /* Allows wrapping for small screens */
  position: relative; /* For absolute positioning of the menu */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease; /* For smooth transition when menu is toggled */
  transform: translateX(0); /* Default state */
}

.nav-links li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #f0f0f0;
}

/* Hamburger Icon Styles */
.hamburger-icon {
  display: none; /* Hide by default */
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger-icon .line {
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically */
    gap: 10px;
    position: absolute; /* Position the menu off-screen */
    top: 60px; /* Below the navbar */
    right: 0;
    width: 50%;
    background-color: #ff1493;
    transform: translateX(100%); /* Initially hide the menu off-screen */
  }

  .nav-links.open {
    transform: translateX(0); /* Show the menu when toggled */
  }

  /* Show hamburger icon on smaller screens */
  .hamburger-icon {
    display: flex; /* Show hamburger icon */
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
