.admin-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .form-section {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-label {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .upload-input {
    display: none;
  }
  
  .image-preview {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    object-fit: cover;
    border: 1px solid #ccc;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    margin-left: 100px;
    cursor: pointer;
  }
  
  .submit-btn:disabled {
    background-color: #aaa;
  }
  
  .list-section {
    margin-top: 20px;
  }
  
  .theory-question-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .theory-question-text {
    flex-grow: 1;
  }
  
  .edit-btn,
  .delete-btn {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #ffc107;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  