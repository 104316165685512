.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8b296b;
    padding: 10px 20px;
    color: white;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allows wrapping for small screens */
    position: relative; /* For absolute positioning of the menu */
  }
  .coins-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .coin-icon {
    width: 5.5rem;
    margin-right: -0.2rem;
    margin-left: 1.2rem;
  }
  
  .coin-number {
    font-size: 1rem;
    margin-right: 1.5rem;
  }
  
  .profile-icon {
    width: 3.5rem;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .logo-image {
    width: 40px;
    height: 40px;
    margin-left: 5px;
  }

  .noti-image {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 20px;
    margin-right: -5px;
    filter: invert(1);
    cursor: pointer;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
    transition: transform 0.3s ease; /* For smooth transition when menu is toggled */
    transform: translateX(0); /* Default state */
  }
  
  .nav-links li a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .nav-links li a:hover {
    color: #f0f0f0;
  }
  .news {
    margin-top: 30px;
    margin-right:5px;
    font-weight: 1000;
  }
  
  .point {
    color: #df0606;
    font-weight: bold;
    font-size: 50px;
    margin-top: -10px;
    margin-right: 20px;
    
  }

  .noti-point {
    color: #df0606;
    font-weight: bold;
    font-size: 50px;
    margin-top: -10px;
    margin-right: 20px;
    
  }
  
  /* Hamburger Icon Styles */
  .hamburger-icon {
    display: none; /* Hide by default */
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }
  
  .hamburger-icon .line {
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
  }
  .logout-button {
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    font-size: 14px;
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column; /* Stack items vertically */
      align-items: center;
    }
  
    .nav-links {
      list-style: none;
      display: none;
      flex-direction: column;
      background-color: #591e46;
      position: absolute;
      top: 60px;
      right: 0;
      width: 30%;
      text-align: start;
      align-items: start;
      z-index: 999;
      border-top: 1px solid #ddd;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    /* Show hamburger icon on smaller screens */
    .hamburger-icon {
      display:flex; /* Show hamburger icon */
      position:absolute;
      right: 20px;
      top: 20px;
    }

    .coin-icon {
      width: 5.5rem;
      margin-right: -0.2rem;
      margin-left: 0.0rem;
    }
  }
  