.email-verification-page {
    text-align: center;
    padding: 30px 20px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 100vh;
  }
  
  .verification-status {
    display: inline-block;
    margin: 20px;
  }
  
  .status-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-weight: bold;
    color: white;
    font-size: 30px;
  }
  
  .verified .status-circle {
    background-color: green;
  }
  
  .unverified .status-circle {
    background-color: gray;
  }
  