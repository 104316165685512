.exam-country-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    min-height: 600px;
  }
  
  .exam-country-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  
  
  
  .examcountries-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .examcountry-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    width: 150px;
  }
  
  .country-flag {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .country-name {
    font-size: 16px;
    font-weight: bold;
  }
  