.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .dropdown,
  .textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .textarea {
    resize: none;
    height: 100px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .checkbox {
    margin-right: 10px;
    transform: scale(1.2);
  }
  
  .toggle-label {
    font-size: 16px;
  }
  
  .image-preview-container {
    text-align: center;
    margin-bottom: 16px;
  }
  
  .image-preview {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .editbutton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  