/* StudentsPage.css */

/* General Page Styles */
.students-page {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #b1a1a1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
  }
  
  /* Heading */
  .students-page h1 {
    font-size: 2rem;
    color: #444;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Dynamic Input Fields */
  .fields {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .field-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .field-input,
  .field-select {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .field-input:focus,
  .field-select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .invalid {
    border-color: #ff4d4d;
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
  }
  
  .remove-button:hover {
    background-color: #e63939;
  }

  .payment-summary-card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .cancel-button,
  .proceed-button {
    padding: 10px 20px;
    border: none;
    margin-right: 50px;
  
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .proceed-button {
    background-color: #4CAF50;
    color: white;
    
  }
  
  .error-text {
    font-size: 0.8rem;
    color: #ff4d4d;
    margin-left: 10px;
  }
  
  /* Actions Buttons */
  .actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .add-button,
  .pay-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .add-button:hover,
  .pay-button:hover {
    background-color: #0056b3;
  }
  
  /* Search Input */
  .search-input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
  }
  
  .search-input:focus {
    border-color: #007bff;
    outline: none;
  }
  .students-table-wrapper {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    
    justify-content: center;
    border-radius: 5px;
  }
  
  .students-table {
    justify-self: center;
    width: 100%;
    min-width: 800px; /* Ensure the table has a minimum width for horizontal scroll */
    border-collapse: collapse;
  }
  
  .table-header,
  .table-row {
    display: flex;
  }
  
  .table-header {
    background-color: #f4f4f4;
    font-weight: bold;
    padding: 10px;
  }
  
  .table-row {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .table-row span {
    flex: 1;
    text-align: left;
    padding: 5px;
  }
  
  /* Students Table */
  .students-table {
    width: 100%;
    max-width: 800px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .table-header {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
  }
  
  .table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-row span {
    font-size: 1rem;
    text-align: center;

  }
  
  /* Loading and Error States */
  .loading,
  .error {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    
  
    .fields {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .students-page h1 {
      font-size: 1.5rem;
    }
  
    .add-button,
    .pay-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .table-header,
    .table-row {
      padding: 5px;
      
    }

    
    .search-input {
      max-width: 100%;
    }
  }
  