.location-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  
  .header-title {
    color: #333;
    text-align: center;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .location-input {
    padding: 10px;
    font-size: 16px;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .add-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .location-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .location-item:hover {
    background-color: #f1f1f1;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  