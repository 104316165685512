.location-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .location-button {
    display: block;
    margin: 10px auto 20px auto;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .location-button:hover {
    background-color: #45a049;
  }
  
  .location-list {
    list-style: none;
    padding: 0;
  }
  
  .location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .location-item span {
    font-size: 16px;
  }
  
  .location-item:nth-child(odd) {
    background-color: #f0f0f0;
  }
  