.container {
    padding: 20px;
  }
  
  .header-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .countries-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .country-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .country-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .country-flag {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .country-name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }