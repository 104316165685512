.promo-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    min-height: 100vh;
  }
  
  .navigate-button,
  .update-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px 5px;
    cursor: pointer;
    width: 50%;
    font-size: 16px;
  }
  
  .navigate-button:hover,
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .update-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    
  }
  
  .promo-list {
    margin-top: 20px;
  }
  
  .promo-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
  }
  
  .user-text,
  .promo-code-text,
  .count-text,
  .profit-text {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .profit-text {
    font-weight: bold;
    color: #28a745;
  }
  