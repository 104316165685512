.delete-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    height: 100vh;
    justify-content: center;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .deleteInput {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
  }
  
  .button {
    background-color: red;
    padding: 15px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    border: none;
  }
  
  .button:disabled {
    background-color: #ccc;
  }
  
  .button-disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  