.edit-number-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .edit-number-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .edit-number-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .edit-number-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-number-button.loading {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .edit-number-button:hover:not(.loading) {
    background-color: #218838;
  }
  

  