.admin-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .admin-input {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
  }
  
  .admin-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
  }
  
  .admin-button:hover {
    background-color: #0056b3;
  }
  
  .coins-list {
    margin-top: 20px;
  }
  
  .coin-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
  }
  
  .coin-details {
    flex: 1;
  }
  
  .coin-actions {
    display: flex;
    gap: 10px;
  }
  
  .action-button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .action-button.edit {
    background-color: #28a745;
    color: white;
  }
  
  .action-button.edit:hover {
    background-color: #218838;
  }
  
  .action-button.delete {
    background-color: #dc3545;
    color: white;
  }
  
  .action-button.delete:hover {
    background-color: #c82333;
  }
  