/* Modal Overlay */
.ExamBeginmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Main Alert Box */
  .exam-alert {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 40%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 80vh; /* Ensure the modal doesn't take up too much vertical space */
    overflow-y: auto; /* Add scrolling if content overflows */
  }
  
  /* Exam Alert Title */
  .exam-alert-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    word-wrap: break-word;
    line-height: 1.3;
  }
  
  /* Exam Alert Image */
  .exam-alert-image {
    width: 30vw; /* Use viewport width to scale with screen size */
    max-width: 120px; /* Ensure image doesn't grow too large */
    height: auto;
    margin-bottom: 1rem;
  }
  
  /* Message under the title */
  .exam-alert-message {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 2rem;
    word-wrap: break-word;
  }
  
  /* Exam Details Section */
  .exam-details {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
  
  /* Exam Title */
  .exam-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  /* Exam Detail Items */
  .exam-detail {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  /* Exam Warning Text */
  .exam-warning {
    font-size: 1rem;
    color: red;
    margin-top: 1rem;
    line-height: 1.4;
    word-wrap: break-word;
  }
  
  /* Button to close modal */
  .exam-btn {
    background-color: #4caf50;
    color: white;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Make the button fill the available space */
    max-width: 300px; /* Limit the width on large screens */
    margin-top: 1rem;
  }
  
  /* Button Hover State */
  .exam-btn:hover {
    background-color: #45a049;
  }
  .cancel-btn {
    background-color: red;
    color: white;
    margin-left: 0px;
  }
  
  .cancel-btn:hover {
    background-color: darkred;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .exam-alert {
      width: 100%; /* For smaller screens, use 80% of the screen width */
      padding: 1.5rem; /* Adjust padding for smaller screens */
    }
  
    .exam-alert-title {
      font-size: 1.2rem; /* Smaller title text */
    }
  
    .exam-alert-message,
    .exam-detail,
    .exam-warning {
      font-size: 1rem; /* Reduce font size for smaller screens */
    }
  
    .exam-btn {
      font-size: 1rem;
      padding: 0.8rem 1.5rem; /* Adjust button padding */
    }
  }
  
  @media (max-width: 480px) {
    .exam-alert {
      max-width: 100%; /* For very small screens, use 90% of the screen width */
      padding: 1rem; /* Reduce padding further */
    }
  
    .exam-alert-title {
      font-size: 1.1rem; /* Even smaller title text */
    }
  
    .exam-btn {
      font-size: 0.9rem; /* Reduce button text size */
      padding: 0.7rem 1rem; /* Adjust button padding for small screens */
    }
  
    .exam-alert-image {
      width: 40vw; /* Scale image for smaller screens */
    }
  }
  