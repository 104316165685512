/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .questions-page {
    padding: 20px;
    background-color: #dabcd2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
  }
  
 
  
  .coin-display {
    display: flex;
    align-items: center;
  }
  
  
  
  .questions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .question-card {
    background: white;
    width: 50%;
    align-self: center;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(68, 56, 56, 0.1);
  }
  
  .question-card h3 {
    margin: 0 0 10px;
  }
  
  .question-content,
  .answer-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .question-image,
  .answer-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
  
  .toggle-answer {
    margin-top: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .toggle-answer:hover {
    background-color: #218838;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .questions-page {
      padding: 15px;
    }
  
    .question-card {
      padding: 10px;
      width: 100%;
    }
  
    .toggle-answer {
      padding: 8px;
    }
  }
  
  @media (max-width: 480px) {
   
    .toggle-answer {
      font-size: 14px;
    }
  }
  