.notifications-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh;
  }
  
  .notifications-header {
    
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  
  
 
  
  .notifications-list {
    margin-top: 20px;
  }
  
  .notification-container {
    
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .notification-message {
    font-size: 16px;
    margin: 0;
  }
  
  .notification-time {
    color: #999;
    font-size: 14px;
  }
  
  .no-notifications {
    text-align: center;
    color: #666;
    font-size: 18px;
  }
  