/* General Reset */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #e4cde3;
    color: #333;
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  .profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .profile-logo {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
  }
  
  h1 {
    text-align: center;
    font-size: 24px;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .logout-button {
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    font-size: 14px;
  }
  
  .logout-button:hover {
    background-color: #ff4949;
  }
  
  .profile-section {
    margin-top: 20px;
  }
  
  .profile-item {
    margin-bottom: 15px;
    font-size: 16px;
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-item strong {
    font-weight: 600;
  }
  
  .profile-item input,
  .profile-item select {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .profie-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin: 20px;
  }
  
  .profie-button:hover {
    background-color: #45a049;
  }
  
  .profie-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  input[type="password"] {
    width: calc(100% - 20px);
    max-width: 300px;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input[type="text"] {
    width: calc(100% - 20px);
    max-width: 300px;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  select {
    width: 100%;
    max-width: 300px;
    width: calc(100% - 20px);
    max-width: 300px;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profie-button.edit-mode {
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .profile-container {
      padding: 15px;
    }
  
    
  
    h1 {
      font-size: 20px;
    }
  
    .profile-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .profile-item strong {
      margin-bottom: 5px;
    }
  
    .profie-button {
      width: 50%;
      text-align: center;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .profile-logo {
      width: 120px;
      height: 120px;
    }
  
    h1 {
      font-size: 18px;
    }
  
    .profie-button {
      padding: 8px 10px;
      font-size: 12px;
    }
  }
  