/* TestLevelsPage.css */

/* Base styles for the container */
.test-levels-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #c2b2c2;
    
  }
  
  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .header {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ddd;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .header .coins {
    display: flex;
    align-items: center;
  }
  
  .header .coins img {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
  
  .header .coins span {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Tests container */
  .tests-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    width: 100%;
    max-width: 1200px;
    margin: 1rem 0;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  /* Test item styles */
  .main-test-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: white;;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .main-test-item.locked {
    background-color: #b44141;
    cursor: not-allowed;
  }
  
  .levelname {
    color: black;
    font-size: 1.5vw;
    
  }
  .main-test-item.clicked {
    background-color: #82e482;
    transform: scale(1.05);
  }
  
  .main-test-item span {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .main-test-item img {
    width: 40px;
    height: 40px;
  }
  

 
  /* ExamBeginAlert responsive styling */
  .exam-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Media queries */
  @media (min-width: 768px) {
    .header h1 {
      font-size: 2rem;
    }
  
    .header .back-button {
      font-size: 1.2rem;
    }
  
    .header .coins span {
      font-size: 1.2rem;
    }
  
    .tests-container {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  
    .mian-test-item {
      height: 120px;
    }
  }
  
  @media (min-width: 1024px) {
    .header {
      padding: 1.5rem;
    }
  
    .header h1 {
      font-size: 2.5rem;
    }

    .tests-container {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  
    .header .back-button {
      font-size: 1.5rem;
    }
  
    .tests-container {
      gap: 1.5rem;
    }
  
    .main-test-item {
      height: 150px;
    }
  }
  