/* Center the container */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
    color: #333;
  }
  
  /* Title styling */
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    margin: 0;
    color: #ff6b6b;
  }
  
  /* Message styling */
  .not-found-message {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: #555;
  }
  
  /* Button styling */
  .not-found-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found-button:hover {
    background-color: #ff4c4c;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 4rem;
    }
  
    .not-found-message {
      font-size: 1.25rem;
    }
  
    .not-found-button {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  }
  