/* General container styling */
.coin-list-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #bfaebb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header styling */
  .coin-list-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Coin list styling */
  .coin-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  /* Individual coin item styling */
  .coin-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .coin-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Coin image styling */
  .coin-image {
    width: 80px;
    height: 80px;
    margin-right: 15px;
  }
  
  /* Coin info styling */
  .coin-info {
    display: flex;
    align-items: center;
  }
  
  .coin-details span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
  }
  
  /* Coin amount styling */
  .coin-amount span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000000;
  }
  
  /* Refresh button styling */
  .refresh-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .refresh-button:hover {
    background-color: #0056b3;
  }
  
  /* Loading spinner styling */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Error message styling */
  .error-text {
    font-size: 1.1rem;
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Media queries for smaller screens */
  @media (max-width: 768px) {
    .coin-list-container h1 {
      font-size: 1.8rem;
    }
  
    .coin-details span, .coin-amount span {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .coin-list-container h1 {
      font-size: 1.5rem;
    }
  
    .coin-item {
      padding: 10px;
    }
  
    .coin-image {
      width: 35px;
      height: 35px;
    }
  
    .coin-details span, .coin-amount span {
      font-size: 0.9rem;
    }
  }
  