.admin-container {
    padding: 20px;
    min-height: 600px;
    display: block;
    justify-items: center;
  }
  
  .text-input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
  }
  
  .category-picker {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
  }
  
  .button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    margin: 20px;
    cursor: pointer;

  }
  
  .button:disabled {
    background-color: #ddd;
  }
  
  .loader {
    text-align: center;
  }
  
  .subject-list {
    list-style-type: none;
    padding: 0;
    
    
  }
  
  .subject-item {
    margin-bottom: 10px;
  }
  
  .test-button, .exam-button, .edit-button, .delete-button {
    padding: 10px;
    margin-right: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .test-button:hover, .exam-button:hover, .edit-button:hover, .delete-button:hover {
    background-color: #45a049;
  }
  