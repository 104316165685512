.admin-edit-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .admin-input {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    font-size: 16px;
  }
  
  .admin-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .admin-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .admin-back-button {
    padding: 10px 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    cursor: pointer;
  }
  