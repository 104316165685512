.home-screen {
  background: url("/src/assets/images/LiteUpb.jpg") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}




.ads-container {
  margin: 20px 5%;

}

.content {
  
  flex: 1;
  padding: 20px 5%;
  flex-direction: column;
  justify-content: center;
  
}

.exam-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 200px;
  justify-items: center;
  padding-left: 100px;
  padding-right: 100px;
  height: auto;
}

.exam-card {
  display: grid;
  background: rgb(169, 51, 137);
  width: 300px;
  height: 300px;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.exam-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.exam-logo .exam-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 4px;
}

.exam-name {
  margin-top: 10px;
  font-weight: bold;
  color: #f8f5f5;
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

 

  .exam-logo .exam-image {
    height: 220px;
  }

  .exam-name {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 15px 3%;
  }

  .exam-logo .exam-image {
    height: 220px;
  }

  .exam-name {
    font-size: 0.8rem;
  }
}
