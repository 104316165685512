.admin-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .dropdown,
  .text-input,
  .file-input,
  .option-button {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
  }
  
  .dropdown {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .text-input {
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkbox-container input {
    margin-right: 10px;
  }
  
  .option-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .option-button:hover {
    background-color: #45a049;
  }
  
  .options-list {
    margin-top: 20px;
  }
  
  .option-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .edit-button {
    background-color: #007bff;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
    margin-left: 40px; 
  }
  
  .delete-button:hover {
    background-color: #c82333;
    
  }
  
  .image-preview-container {
    text-align: center;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 300px;
    margin-top: 15px;
    border-radius: 8px;
  }