/* Background and layout settings */
.year-background-image {
   
    background-size: cover;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
}

/* Main container with a fixed max-width */
.year-container {
    max-width: 100%;
    width: 50%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 20px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -20%; /* Add some space for smaller screens */
    box-sizing: border-box;
}

/* Header with flexible spacing */
.year-header {
    
    
    margin-bottom: 20px;
    text-align: center;
}



/* Grid layout for years, automatically adjusting based on screen size */
.years-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Flexible column sizes */
    gap: 20px;
    align-self: center;
    padding: 0; /* Remove padding from grid */
}

/* Year buttons with hover effect */
.year-button {
    background-color: #822d8d;
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px; /* Make font size adjustable for small screens */
}

/* Hover effect for year buttons */
.year-button:hover {
    transform: scale(1.05);
    background-color: rgb(203, 186, 203);
}

/* Refresh button styling */
.year-refresh-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

/* No data found message */
.year-no-data {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

/* Error alert styling */
.year-error-alert {
    text-align: center;
    min-height: 100vh;
    color: red;
    margin: 20px;
}

.year-error-alert button {
    margin: 10px;
    padding: 10px 15px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Loading message styling */
.year-loading {
    text-align: center;
    font-size: 24px;
    color: #555;
    margin-top: 50px;
}

/* Media queries for more responsiveness */
@media (max-width: 768px) {
    .year-container {
        padding: 15px;
        margin-top: -80%;
        justify-items: normal;
        width: 100%;
    }

    .year-header {
        flex-direction: column; /* Stack header elements vertically on smaller screens */
        align-items: center;
    }

    .year-gold-info {
        margin-top: 10px;
    }

    .years-list {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)); /* Adjust column width */
    }

    .year-button {
        font-size: 12px; /* Smaller font size for smaller screens */
        padding: 12px;
    }

    .refresh-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .year-container {
        padding: 10px;
        width: 100%;
        justify-items: normal;
    }

    .year-button {
        font-size: 11px; /* Adjust font size even more for very small screens */
        padding: 10px;
    }

    .year-refresh-button {
        font-size: 12px;
    }
}
