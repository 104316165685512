.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .form-group button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-group button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .question-list {
    margin-top: 20px;
    max-width: 100%;

    
  }
  
  .question-item {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; 
    display: block;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  
  .question-details {
    max-width: 100%;

  }
  
  .question-details p {
    margin: 0;
    color: #495057;
  }
  
  .actions{
    margin-left: 10px;
    padding: 5px 10px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }


  .delete-button {
    background-color: #dc3545;
  }
  
  .actions button:hover {
    opacity: 0.9;
  }
  