/* PromoCodeScreen.css */

/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #d0bed1;
    color: #333;
  }
  
  .promo-code-screen {
    display: flex;
    flex-direction: column;
    justify-items: center;
    min-height: 100vh;
  }
  
  /* Header Styles */
  .promo-header {
    background-color:white;
    color: white;
    padding: 1rem;
    
    justify-content: space-between;
    align-items: center;
  }
  
  .promo-header h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  
 span {
    font-weight: bold;
  }
  
  /* Main Content Styles */
  .promo-content {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    justify-content: center;
    background-color:white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .promo-details {
    width: 100%;
   
  }
  
  .liteup-icon {
    display: block;
    margin: 0 auto 1rem;
    width: 200px;
    height: 200px;
  }
  
  h2 {
    margin: 1rem 0;
    font-size: 1.2rem;
    color: #4caf50;
  }
  
  .profit-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .profit-item {
    background-color: #f0f0f0;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  /* Form Field Styles */
  .promo-field {
    margin-bottom: 1rem;
  }
  
  .promo-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .promo-field input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .promo-field input:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
  
  /* Button Styles */
  .promo-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .promo-button:hover {
    background-color: #45a049;
  }
  
  .promo-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .promo-header {
      flex-direction: column;
      text-align: center;
    }
  
    .promo-content {
      padding: 1rem;
    
      width: 100%;

    }
  
    .promo-field input {
      font-size: 0.9rem;
    }
  
    .promo-button {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .promo-header h1 {
      font-size: 1.2rem;
    }
  
    .coin-display .gold-icon {
      width: 20px;
      height: 20px;
    }
  
    .liteup-icon {
      width: 80px;
      height: 80px;
    }
  }
  