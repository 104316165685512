.edit-test-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-height: 800px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: block; 
  }
  
  .edit-test-container h1 {
    text-align: center;
    color: #333;
   
    margin-bottom: 20px;
  }
  
  .text-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .text-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .update-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .update-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  