.admin-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form-input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
  }
  
  .form-button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .questions-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .question-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .question-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .action-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .action-button.delete {
    background-color: #ff4d4d;
    color: white;
  }
  
  .action-button:not(.delete) {
    background-color: #007BFF;
    color: white;
  }
  