/* Modal styling */
.alert-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
}

/* Background styling */
.alert-background {
  background-size: cover;
  background-position: center;
  padding: 5%;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  height: auto;
  max-height: 150%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/* Main container for the alert content */
.alert-container {
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

/* Card container */
.alert-card-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Styling for alert card */
.alert-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/src/assets/images/congrats.jpg") no-repeat center / cover;
  width: 100%;
  max-width: 500px;
}

/* Image styling */
.alert-image {
  width: 70%;
  max-width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
}

/* Title and message styling */
.alert-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

.alert-message {
  font-size: 1.2rem;
  color: white;
  line-height: 1.5;
}

/* Styling for the 'Next' button */
.alert-next-button {
  background-color: #5cb85c;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  border: none;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease;
}

.alert-next-button:hover {
  background-color: #4cae4c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .alert-container {
    padding: 1.5rem;
   
  }

  .alert-title {
    font-size: 1.5rem;
  }

  .alert-message {
    font-size: 1rem;
  }

  .alert-next-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.5rem;
  }

  .alert-image {
    width: 60%;
  }
  
  .alert-background {
    margin: auto;
    width: 100%;
    
  }


}

@media (max-width: 480px) {
  .alert-container {
    padding: 1rem;
    
  }

  .alert-background {
    position: fixed;
    width: 100%;
  }

  .alert-title {
    font-size: 1.2rem;
  }

  .alert-message {
    font-size: 0.9rem;
  }

  .alert-next-button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .alert-image {
    width: 50%;
  }

  .alert-card {
    padding: 1rem;
  }
}
